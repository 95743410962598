@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=JetBrains+Mono:wght@100;200;300;400;500;600;700;800&family=Literata:opsz,wght@7..72,300;7..72,400;7..72,500&family=Roboto:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-family: 'JetBrains Mono', monospace;
    /* font-family: 'Literata', serif; */
    /* font-family: 'Roboto', sans-serif; */
}

* .display_flex_center{
    display: flex;
    align-items: center;
    justify-content: center;
}

* button{
    cursor: pointer;
    font-weight: 600;
}

h1, h2, h3, h4{
    cursor: default;
}

.react-flow__attribution{
    display: none;
}

.react-flow__handle {
    background: transparent;
    border: none;
}

.base_page{
    width: 100vw;
    height: 100vh;
}

.input{
    height: 45px;
    padding-left: 10px;
    box-sizing: border-box;

    font-size: 16px;

    border: 2px solid #c5b6b4;
    border-radius: 8px;
    outline: none;
}

.input:focus, .input:hover {
    border-color: #4d4d4d;
    background-color: #f8f8f8;
}